<template>
    <div class="box">
        <div class="tips">
            <h1>Dicas</h1>
            <ol>
                <li>
                    Conversar com os alunos e escutar atentamente reclamações ou
                    sugestões;
                </li>

                <li>Estimular os estudantes a informar os casos;</li>

                <li>
                    Reconhecer e valorizar as atitudes da garotada no combate ao problema;
                </li>

                <li>Não minimize a situação de ocorrência do bullying;</li>

                <li>Identifique quem pratica o bullying;</li>

                <li>Incentive elogios.</li>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TipsBullying'
    };
</script>

<style scoped>
    .box {
        max-width: 600px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .tips h1 {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .tips ol li {
        margin-top: 20px;
        text-align: justify;
    }
</style>
